<template>
  <div>
    <b-row>
      <b-col>
        <h1 class="font-weight-bolder">
          {{ $t('new', { type: $t('project') }) }}
        </h1>
      </b-col>
    </b-row>
    <b-card
      no-body
      class="overflow-hidden"
    >
      <validation-observer ref="projectValidation">
        <b-row>
          <b-col
            sm="12"
            lg="6"
            class="order-lg-1"
          >
            <b-card-body>
              <b-form @submit.prevent="validateForm()">
                <b-row>
                  <b-col sm="12">
                    <b-form-group :label="$t('projectName')">
                      <validation-provider
                        #default="{ errors }"
                        name="Nombre del proyecto"
                        rules="required"
                      >
                        <b-form-input
                          v-model="project.name"
                          size="lg"
                          class="font-weight-bolder"
                          autocomplete="off"
                          type="text"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    sm="12"
                    lg="6"
                  >
                    <b-form-group label="Folio">
                      <validation-provider
                        #default="{ errors }"
                        name="Folio"
                        rules="required"
                      >
                        <b-form-input
                          v-model="project.folio"
                          size="lg"
                          class="font-weight-bolder"
                          autocomplete="off"
                          type="text"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col sm="12">
                    <b-form-group :label="$t('address')">
                      <gmap-autocomplete
                        ref="googleAddress"
                        class="form-control"
                        @place_changed="updateCenter($event)"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col sm="12">
                    <b-form-group :label="$t('googleAddressUrl')">
                      <validation-provider
                        #default="{ errors }"
                        name="url"
                        rules="required"
                      >
                        <b-form-input
                          v-model="project.google_maps_url"
                          class="font-weight-bolder"
                          autocomplete="off"
                          disabled
                          type="text"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <div v-if="allowBilling">
                  <h4>{{ $t('billing') }}</h4>
                  <b-row>
                    <b-col
                      sm="12"
                      lg="4"
                    >
                      <b-form-group label="RFC">
                        <validation-provider
                          #default="{ errors }"
                          name="RFC"
                          rules=""
                        >
                          <b-form-input
                            v-model="project.tax_information_attributes.rfc"
                            size="lg"
                            class="font-weight-bolder"
                            autocomplete="off"
                            type="text"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col
                      sm="12"
                      lg="8"
                    >
                      <b-form-group :label="$t('bussinessName')">
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('bussinessName')"
                          rules=""
                        >
                          <b-form-input
                            v-model="
                              project.tax_information_attributes.corporate_name
                            "
                            size="lg"
                            class="font-weight-bolder"
                            autocomplete="off"
                            type="text"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col
                      sm="12"
                      lg="3"
                    >
                      <b-form-group :label="$t('postalCode')">
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('postalCode')"
                          rules=""
                        >
                          <b-form-input
                            v-model="
                              project.tax_information_attributes.postal_code
                            "
                            size="lg"
                            class="font-weight-bolder"
                            autocomplete="off"
                            type="number"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col
                      sm="12"
                      lg="3"
                    >
                      <b-form-group :label="$t('roadName')">
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('roadName')"
                          rules=""
                        >
                          <b-form-input
                            v-model="project.tax_information_attributes.street"
                            size="lg"
                            class="font-weight-bolder"
                            autocomplete="off"
                            type="text"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col
                      sm="12"
                      lg="3"
                    >
                      <b-form-group :label="$t('extNumber')">
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('extNumber')"
                          rules=""
                        >
                          <b-form-input
                            v-model="
                              project.tax_information_attributes.ext_number
                            "
                            size="lg"
                            class="font-weight-bolder"
                            autocomplete="off"
                            type="number"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col
                      sm="12"
                      lg="3"
                    >
                      <b-form-group :label="$t('intNumber')">
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('intNumber')"
                        >
                          <b-form-input
                            v-model="
                              project.tax_information_attributes.int_number
                            "
                            size="lg"
                            class="font-weight-bolder"
                            autocomplete="off"
                            type="number"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col
                      sm="12"
                      lg="6"
                    >
                      <b-form-group :label="$t('suburb')">
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('suburb')"
                          rules=""
                        >
                          <b-form-input
                            v-model="project.tax_information_attributes.suburb"
                            size="lg"
                            class="font-weight-bolder"
                            autocomplete="off"
                            type="text"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col
                      sm="12"
                      lg="6"
                    >
                      <b-form-group :label="$t('locality')">
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('locality')"
                          rules=""
                        >
                          <b-form-input
                            v-model="
                              project.tax_information_attributes.locality
                            "
                            size="lg"
                            class="font-weight-bolder"
                            autocomplete="off"
                            type="text"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col
                      sm="12"
                      lg="6"
                    >
                      <b-form-group :label="$t('municipality')">
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('municipality')"
                          rules=""
                        >
                          <b-form-input
                            v-model="project.tax_information_attributes.city"
                            size="lg"
                            class="font-weight-bolder"
                            autocomplete="off"
                            type="text"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col
                      sm="12"
                      lg="6"
                    >
                      <b-form-group :label="$t('state')">
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('state')"
                          rules=""
                        >
                          <b-form-input
                            v-model="project.tax_information_attributes.state"
                            size="lg"
                            class="font-weight-bolder"
                            autocomplete="off"
                            type="text"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col
                      sm="12"
                      lg="6"
                    >
                      <b-form-group :label="$t('email')">
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('email')"
                        >
                          <b-form-input
                            v-model="project.tax_information_attributes.email"
                            size="lg"
                            class="font-weight-bolder"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </div>

                <b-row>
                  <b-col class="d-flex justify-content-end">
                    <b-button
                      variant="primary"
                      size="lg"
                      class="px-5"
                      type="submit"
                    >
                      {{ $t('save') }}
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </b-card-body>
          </b-col>
          <b-col
            sm="12"
            lg="6"
            class="order-lg-2"
          >
            <div id="root">
              <gmap-map
                ref="map"
                :center="center"
                :zoom="zoom"
                style="width: 100%; height: 750px"
                @click="setMarker($event)"
              >
                <!-- TODO add pin -->
                <GmapMarker :position="marker" />
              </gmap-map>
            </div>
          </b-col>
        </b-row>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import * as google from 'gmap-vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },

  data() {
    return {
      project: {
        name: '',
        contact_name: '',
        address_attributes: {
          state: '',
          country: '',
          city: '',
          street: '',
          ext_number: '',
          postal_code: '',
          lat: '',
          lng: '',
          suburb: '',
        },
        tax_information_attributes: {
          rfc: null,
          corporate_name: null,
          postal_code: null,
          street: null,
          ext_number: null,
          int_number: null,
          suburb: null,
          locality: null,
          city: null,
          state: null,
          email: null,
        },
        google_maps_url: '',
      },
      required,
      center: { lat: 25.6866142, lng: -100.3161126 },
      marker: {},
      zoom: 12,
    }
  },
  computed: {
    ...mapGetters('suppliers', ['suppliers', 'newProviderFiles']),
    allowBilling() {
      return ['Nuevo León', 'Nuevo Leon'].includes(
        this.project.address_attributes?.state,
      )
    },
  },
  created() {
    // this.project.folio = Math.floor(Math.random() * (100 - 1) + 1)
  },
  methods: {
    ...mapActions('projects', ['addProject']),
    updateCenter(place) {
      this.center = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      }
      this.zoom = 16
      // @Autocomplete put a marker in the map - disabled
      // this.marker = {
      //   lat: place.geometry.location.lat(),
      //   lng: place.geometry.location.lng(),
      // }
      this.companyAddress = place
      this.project.address_attributes = this.getAdress(place)
    },
    setMarker(event) {
      // goecode manual marker to get address address - disabled
      // eslint-disable-next-line
      // const geocoder = new google.gmapApi().maps.Geocoder
      // const latlng = { lat: event.latLng.lat(), lng: event.latLng.lng() }
      // geocoder.prototype.geocode({ location: latlng }, (results, status) => {
      //   if (status === 'OK') {
      //     if (results[1]) {
      //       this.$refs.googleAddress.$el.value = results[1].formatted_address
      //       this.project.address_attributes = this.getAdress(results[1])
      //     } else {
      //       this.$toast({
      //         component: ToastificationContent,
      //         props: {
      //           title: 'Introduzca la direccion manualmente',
      //           icon: 'EditIcon',
      //           variant: 'danger',
      //         },
      //       })
      //     }
      //   }
      // })
      const lat = event.latLng.lat()
      const lng = event.latLng.lng()
      this.project.google_maps_url = `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`
      this.marker = {
        lat,
        lng,
      }
    },

    getAdress(place) {
      const address = {
        name: '',
        state: '',
        country: '',
        city: '',
        street: '',
        ext_number: '',
        int_number: '',
        postal_code: '',
        lat: '',
        lng: '',
        suburb: '',
        url: '',
        formatted_address: '',
      }
      place.address_components.forEach(x => {
        if (x.types.includes('administrative_area_level_1')) {
          address.state = x.long_name
        }
        if (x.types.includes('country')) {
          address.country = x.long_name
        }
        if (x.types.includes('city') || x.types.includes('locality')) {
          address.city = x.long_name
        }
        if (x.types.includes('street') || x.types.includes('route')) {
          address.street = x.long_name
        }
        if (
          x.types.includes('ext_number')
          || x.types.includes('street_number')
        ) {
          address.ext_number = x.long_name
        }
        if (x.types.includes('postal_code')) {
          address.postal_code = x.long_name
        }
        if (
          x.types.includes('suburb')
          || x.types.includes('sublocality_level_1')
        ) {
          address.suburb = x.long_name
        }
      })
      address.formatted_address = place.formatted_address
      address.lat = place.geometry.location.lat()
      address.lng = place.geometry.location.lng()
      address.url = place.url
      return address
    },
    validateForm() {
      this.$refs.projectValidation.validate().then(success => {
        if (success) {
          this.project.customer_id = this.$route.params.customerId
          this.addProject({
            ...this.project,
          }).then(response => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$t('projectCreated'),
                icon: 'EditIcon',
                variant: 'success',
              },
            })
            this.$router.push({
              name: 'quotes',
              params: { projectId: response.id },
            })
          })
        }
      })
    },
  },
}
</script>

<style></style>
